// 
// _estate.scss
// 

.filter-text {
    display: block;
    margin-bottom: 0.75rem;
    color: $font-gray;
    font-size: px2rem(12);
}

.estate-list {
    display: grid;
    gap: 1rem;
    margin: 0 -15px;
    padding: 0;
    list-style: none;

    .estate-list-item {
        padding: 15px;
        font-size: px2rem(14);
        color: $color-gray;
        box-shadow: $box-shadow;

        .name {
            font-size: px2rem(19.2);
        }

        small {
            font-size: 80%;
        }

        .top-row {
            margin-top: -0.25rem;

            [class*="col"] {
                display: flex;
                align-items: center;
                margin-top: 0.25rem;
            }

            .custom-control {
                margin-right: -0.25rem;

                .custom-control-label {

                    &:before,
                    &:after {
                        top: 3px;
                    }
                }
            }
        }

        .favorite-heart {
            position: relative;
        }

        .favorite {
            position: absolute;
            right: 0;
            display: inline-flex;
            padding: 0.25rem;
            border: 1px solid $color-main;

            .eva {
                @include size(15px);
                top: 0;
            }
        }

        .brand {
            @include ellipsis();
        }

        .date {
            white-space: nowrap;
        }

        .name {
            color: $font-dark;
            font-weight: 500;
        }

        .image {
            position: relative;
            padding-bottom: 67%;

            img {
                @include size();
                position: absolute;
                border-radius: 0.25rem;
                object-fit: cover;
            }
        }

        .price {
            color: $color-red;
            font-size: px2rem(20);
            font-weight: 500;
        }
    }
}
//style-2

//----------------
// Color
//----------------

$color-primary: #6C1B1A;
$color-secondary: #B79156;
$color-dark: #422717;
$color-middle: #955629;
$color-light: #FAE2A5;

$color-white: #FFF;
$color-black: #3E3A39;

$zoom: 1.05;


@page {
    size: A4 portrait;
}

// .page-wrap {
//     // 疊圖參考
//     position: relative;
//     &::before {
//         content: '';
//         width: 21cm * $zoom;
//         height: 29.7cm * $zoom;
//         display: block;
//         position: absolute;
//         top: 0;
//         left: 0;
//         opacity: 0.4;
//         background-repeat: no-repeat;
//         background-size: contain;

//         background-image: url('@/assets/imgs/edm/ref/edm-10.png'); // 1
//         // background-image: url('@/assets/imgs/edm/ref/edm-11.png'); // 2
//         // background-image: url('@/assets/imgs/edm/ref/edm-12.png'); // 4
//         // background-image: url('@/assets/imgs/edm/ref/edm-13.png'); // 6
//         // background-image: url('@/assets/imgs/edm/ref/edm-14.png'); // 8
//     }
// }

.edm-2-1, .edm-2-2, .edm-2-4, .edm-2-6, .edm-2-8{
    .page-wrap {
        width:  21cm * $zoom;
        height: 29.7cm * $zoom;
        margin: 0 auto;
    }

    .page-header {
        height: 2.13cm * $zoom;
        padding: 0.55cm * $zoom 0.8cm * $zoom 0.47cm * $zoom;
        box-sizing: border-box;
        position: relative;

        .logo-wrap {
            width: 5cm * $zoom;
            height: 1.1198cm * $zoom;
            img{
                max-width: 100%;
            }
        }

        .branch-name {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-primary;
            width: 9.2214cm * $zoom;
            padding: 0.48cm * $zoom 0.66cm * $zoom 0.44cm * $zoom;
            box-sizing: border-box;

            span {
                color: $color-light;
                font-size: 16pt * $zoom;
                font-weight: bold;
            }
        }
    }

    .page-footer {
        background-color: $color-secondary;
        height: 2.1824cm * $zoom;
        position: relative;

        .contact-wrap {
            position: absolute;
            bottom: 0.5cm * $zoom;
            left: 50%;
            transform: translateX(-50%);
            width: 91.089%;
        }
    }

    .contact-wrap {
        background-color: $color-dark;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.75cm * $zoom;
        padding: 0.35cm * $zoom 0.47cm * $zoom 0.39cm * $zoom;
        box-sizing: border-box;

        .info-wrap {
            display: flex;
            justify-content: center;
            column-gap: 0.3cm * $zoom;

            .img-wrap {
                width: 2.4cm * $zoom;
                height: 2.4cm * $zoom;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text-wrap {

                // 標題
                .info-title {
                    color: $color-light;
                    font-size: 11pt * $zoom;
                    line-height: 1;
                    letter-spacing: 1pt * $zoom;
                    font-weight: bold;
                    display: block;

                    & + .info-title {
                        margin-top: 7pt * $zoom;
                    }
                }

                // 姓名
                .info-name {
                    margin: 0;
                    color: $color-white;
                    font-size: 23.775pt * $zoom;
                    line-height: 1.2;
                    letter-spacing: 0.25pt * $zoom;
                    font-weight: bold;
                    margin-top: 5pt * $zoom;
                }

                // 電話
                .info-tel {
                    margin: 0;
                    color: $color-white;
                    font-size: 23.775pt * $zoom;
                    line-height: 1;
                    letter-spacing: 1.4pt * $zoom;
                    font-weight: bold;
                    white-space: nowrap;
                }

                // 地址
                .info-address {
                    margin: 0;
                    margin-top: 2pt * $zoom;
                    color: $color-white;
                    font-weight: bold;
                    font-size: 12pt * $zoom;
                    letter-spacing: 0.3pt * $zoom;
                    line-height: 1.4;
                }
            }
        }
    }

    .page-body {
        position: relative;
        height: calc( 100% - 2.4597cm * $zoom - 2.1824cm * $zoom );
        margin-top: 0.3297cm * $zoom;
        padding: 0 0.94cm * $zoom 2.41cm * $zoom 0.88cm * $zoom;
        box-sizing: border-box;

        // left border
        &::before {
            content: '';
            background-color: $color-secondary;
            width: 0.3711cm * $zoom;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        // 物件
        .card-wrap {
            cursor: pointer;

            // 物件圖片
            .card-hero-img,
            .card-img {
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            // 物件標題
            .card-title {
                background-color: $color-middle;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                margin-bottom: 0;

                p {
                    line-height: 1;
                    margin: 0;
                    color: $color-white;
                    font-weight: bold;
                }
            }

            // 物件資訊
            .card-detail {

                // 清單標題
                .detail-list-title {
                    color: $color-dark;
                    font-weight: bold;
                    border-bottom: 2pt * $zoom solid $color-secondary;
                }

                // 資訊清單
                .detail-list {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    // 細節項目
                    .list-item {

                        // 項目標題
                        .item-title {
                            color: $color-secondary;
                            font-weight: bold;
                        }
        
                        // 項目內容
                        .item-content {
                            font-weight: bold;
                            color: $color-black;
                        }
                    }
                }
            }

            // 物件價格
            .card-price {
                background-color: $color-primary;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    color: $color-light;
                    text-align: center;
                    font-weight: bold;
                    line-height: 1;
                }
            }
        }
    }
}

//--------------------------
// 根據物件數量決定排版樣式
//--------------------------


// 1個物件
.edm-2-1{
    .layout-count-1 {

        .page-body {
            margin-top: 0;
            padding-top: 0;
            padding-left: 0.3711cm * $zoom;
            padding-right: 0;
            padding-bottom: 2.14cm * $zoom;
            height: calc( 100% - 2.13cm * $zoom - 2.1824cm * $zoom );

            // 物件
            .card-wrap {
                height: 100%;
                display: flex;
                flex-direction: column;

                // 物件首圖
                .card-hero-img {
                    flex-shrink: 0;
                    height: 8.0955cm * $zoom;
                }

                // 物件 header
                .card-heard {
                    flex-shrink: 0;
                    display: flex;

                    // 物件標題
                    .card-title {
                        flex-grow: 1;
                        height: 1.756cm * $zoom;
                        padding: 0 12pt * $zoom;

                        p {
                            font-size: 24.969pt * $zoom;
                            letter-spacing: 0.6pt * $zoom;
                        }
                    }

                    // 物件價格
                    .card-price {
                        flex-shrink: 0;
                        width: 6.1469cm * $zoom;
                        height: 1.756cm * $zoom;
                        
                        span {
                            font-size: 30pt * $zoom;
                            letter-spacing: 2.6pt * $zoom;
                        }
                    }
                }

                // 物件內容
                .card-info {
                    flex-grow: 1;
                    display: flex;
                    column-gap: 27.8pt * $zoom;
                    padding: 0.6cm * $zoom 1.41cm * $zoom 0 1.04cm * $zoom;

                    .card-info-inner {
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.5cm * $zoom;

                        // 物件圖片
                        .card-img {
                            flex-shrink: 0;
                            border: 2pt * $zoom solid $color-secondary;
                            width: 5.0197cm * $zoom;
                            height: 3.7648cm * $zoom;
                        }
                    }

                    // 物件資訊
                    .card-detail {
                        flex-grow: 1;

                        // 清單標題
                        .detail-list-title {
                            font-size: 14pt * $zoom;
                            line-height: 1.6;
                            margin-bottom: 16.2pt * $zoom;
                        }

                        // 資訊清單
                        .detail-list {
                            display: flex;
                            flex-direction: column;
                            row-gap: 0.16cm * $zoom;

                            // 細節項目
                            .list-item {
                                display: flex;
                                flex-direction: column;

                                // 項目標題
                                .item-title {
                                    display: block;
                                    font-size: 12pt * $zoom;
                                    line-height: 1.6;

                                    // left border
                                    &::before {
                                        content: '';
                                        display: inline-block;
                                        margin-right: 0.36cm * $zoom;
                                        width: 0.12cm * $zoom;
                                        height: 0.4cm * $zoom;
                                        background-color: $color-secondary;
                                    }
                                }
                
                                // 項目內容
                                .item-content {
                                    display: block;
                                    margin-left: 0.48cm * $zoom;
                                    font-size: 14pt * $zoom;
                                    line-height: 1.5;
                                    letter-spacing: 0.25pt * $zoom;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 2個物件
.edm-2-2{
    .layout-count-2 {

        .page-body {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1.13cm * $zoom;

            // 物件
            .card-wrap {
                border: 1pt * $zoom solid $color-secondary;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                // 物件圖片
                .card-img {
                    flex-shrink: 0;
                    width: 100%;
                    height: 6.36cm * $zoom;
                }

                // 物件標題
                .card-title {
                    flex-shrink: 0;
                    height: 2.2063cm * $zoom;
                    padding: 0 10pt * $zoom;
                    justify-content: center;

                    p {
                        font-size: 17.515pt * $zoom;
                        text-align: center;
                        line-height: 1.15;
                    }
                }

                // 物件資訊
                .card-detail {
                    flex-grow: 1;
                    padding: 0.45cm * $zoom 0.54cm * $zoom;

                    // 資訊清單
                    .detail-list {
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.28cm * $zoom;

                        // 細節項目
                        .list-item {
                            display: flex;
                            flex-direction: column;

                            // 項目標題
                            .item-title {
                                display: block;
                                font-size: 12pt * $zoom;
                                line-height: 1.5;

                                // left border
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    margin-right: 0.36cm * $zoom;
                                    width: 0.12cm * $zoom;
                                    height: 0.4cm * $zoom;
                                    background-color: $color-secondary;
                                }
                            }
            
                            // 項目內容
                            .item-content {
                                display: block;
                                margin-left: 0.52cm * $zoom;
                                font-size: 14pt * $zoom;
                                line-height: 1.5;
                                letter-spacing: 0.25pt * $zoom;
                            }
                        }
                    }
                }

                // 物件價格
                .card-price {
                    flex-shrink: 0;
                    height: 1.756cm * $zoom;
                    
                    span {
                        font-size: 30pt * $zoom;
                        letter-spacing: 2.5pt * $zoom;
                    }
                }
            }
        }
    }
}

// 4個物件
.edm-2-4{
    .layout-count-4 {

        .page-body {
            display: flex;
            flex-direction: column;
            row-gap: 0.34cm * $zoom;

            // 物件
            .card-wrap {
                flex-grow: 1;
                border: 1pt * $zoom solid $color-secondary;
                display: flex;

                // 物件圖片
                .card-img {
                    flex-shrink: 0;
                    width: 6.3839cm * $zoom;
                }

                // 物件內容
                .card-info {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    // 物件標題
                    .card-title {
                        flex-shrink: 0;
                        height: 1.2806cm * $zoom;
                        padding: 0 0.37cm * $zoom;
                        
                        p {
                            font-size: 16pt * $zoom;
                            line-height: 1;
                            letter-spacing: 0.6pt * $zoom;
                        }
                    }
                    
                    .card-info-inner {
                        flex-grow: 1;
                        display: flex;

                        // 物件資訊
                        .card-detail {
                            flex-grow: 1;
                            display: flex;
                            align-items: center;
                            // padding: 0.68cm * $zoom 0.37cm * $zoom 0.58cm * $zoom 0.37cm * $zoom;
                            padding-left: 0.37cm * $zoom;
                            padding-right: 0;

                            // 資訊清單
                            .detail-list {
                                display: grid;
                                grid-template-columns: 0.6fr 0.4fr;
                                row-gap: 16pt * $zoom;
                                // column-gap: 10pt * $zoom;
                                width: 100%;

                                // 細節項目
                                .list-item {
                                    display: flex;
                                    flex-direction: column;

                                    // 項目標題
                                    .item-title {
                                        display: block;
                                        font-size: 12pt * $zoom;
                                        line-height: 1.4;

                                        // left border
                                        &::before {
                                            content: '';
                                            display: inline-block;
                                            margin-right: 0.36cm * $zoom;
                                            width: 0.12cm * $zoom;
                                            height: 0.4cm * $zoom;
                                            background-color: $color-secondary;
                                        }
                                    }
                    
                                    // 項目內容
                                    .item-content {
                                        display: block;
                                        margin-left: 0.48cm * $zoom;
                                        font-size: 14pt * $zoom;
                                        line-height: 1.2;
                                        letter-spacing: 0.25pt * $zoom;
                                    }
                                }
                            }
                        }

                        // 物件價格
                        .card-price {
                            flex-shrink: 0;
                            width: 3.9042cm * $zoom;

                            span {
                                font-size: 24pt * $zoom;
                                letter-spacing: 1pt * $zoom;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 6個物件
.edm-2-6{
    .layout-count-6 {

        .page-body {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 0.3cm * $zoom;
            row-gap: 0.34cm * $zoom;

            // 物件
            .card-wrap {
                border: 1pt * $zoom solid $color-secondary;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                // 物件圖片
                .card-img {
                    flex-shrink: 0;
                    height: 4.6913cm * $zoom;
                }

                // 物件標題
                .card-title {
                    flex-shrink: 0;
                    height: 1.2619cm * $zoom;
                    margin-bottom: 0;
                    padding: 0 0.1cm * $zoom;
                    justify-content: center;

                    p {
                        font-size: 13pt * $zoom;
                        text-align: center;
                        letter-spacing: 0.5pt * $zoom;
                    }
                }

                // 物件資訊
                .card-detail {
                    flex-grow: 1;
                    padding: 0.45cm * $zoom 0.34cm * $zoom 0.3cm * $zoom;
                    padding-right: 0;

                    // 資訊清單
                    .detail-list {
                        display: flex;
                        flex-direction: column;
                        row-gap: 2.2pt * $zoom;

                        // 細節項目
                        .list-item {
                            display: grid;
                            grid-template-columns: 0.29fr 0.71fr;

                            // 項目標題
                            .item-title {
                                display: block;
                                font-size: 12pt * $zoom;
                                line-height: 1.7;
                            }
            
                            // 項目內容
                            .item-content {
                                display: block;
                                padding-top: 0.02cm;
                                font-size: 14pt;
                                line-height: 1.5;
                                // letter-spacing: 0.25pt * $zoom;
                            }
                        }
                    }
                }

                // 物件價格
                .card-price {
                    flex-shrink: 0;
                    height: 1.2946cm * $zoom;
                    
                    span {
                        font-size: 22.117pt * $zoom;
                        letter-spacing: 1pt * $zoom;
                    }
                }
            }
        }
    }
}

// 8個物件
.edm-2-8{
    .layout-count-8 {

        .page-body {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 0.45cm;
            row-gap: 0.4cm;

            // 物件
            .card-wrap {
                border: 1pt * $zoom solid $color-secondary;
                display: flex;
                flex-direction: column;
                
                // 物件標題
                .card-title {
                    flex-shrink: 0;
                    height: 1.1671cm * $zoom;
                    padding: 0 10pt * $zoom;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        font-size: 17.515pt;
                        // line-height: 1.1671cm * $zoom;
                        letter-spacing: 0.8pt * $zoom;
                        text-align: center;
                    }
                }

                // 物件內容
                .card-info {
                    flex-grow: 1;
                    display: flex;

                    // 物件圖片
                    .card-img {
                        flex-shrink: 0;
                        width: 4.7681cm * $zoom;
                    }

                    .card-info-inner {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        // 物件資訊
                        .card-detail {
                            flex-grow: 1;
                            padding: 0.25cm 0.22cm 0.15cm;
                            padding-right: 0;

                            // 資訊清單
                            .detail-list {
                                display: flex;
                                flex-direction: column;
                                row-gap: 2.2pt * $zoom;

                                // 細節項目
                                .list-item {
                                    display: grid;
                                    grid-template-columns: 0.29fr 0.71fr;

                                    // 項目標題
                                    .item-title {
                                        display: block;
                                        font-size: 9pt * $zoom;
                                        line-height: 1.5;
                                    }
                    
                                    // 項目內容
                                    .item-content {
                                        display: block;
                                        padding-top: 0.02cm;
                                        font-size: 11pt;
                                        line-height: 1.3;
                                        // letter-spacing: 0.25pt * $zoom;
                                    }
                                }
                            }
                        }

                        // 物件價格
                        .card-price {
                            flex-shrink: 0;
                            height: 1.0756cm * $zoom;
                            
                            span {
                                font-size: 18.375pt * $zoom;
                                letter-spacing: 1pt * $zoom;
                            }
                        }
                    }
                }
            }
        }
    }
}
//
// _dropdown.scss
// 

.dropdown-toggle{
	display: inline-block;

	// &:after{
	// 	@include size(13px);
	// 	border: 0;
	// 	background: url(../images/icon/arrow_carrot-down.svg) 50% 50% no-repeat;
	// 	background-size: 22px auto;
	// 	margin-left: 0;
	// 	vertical-align: 0;
	// }
}

.dropdown-menu{
	border: 0;
	box-shadow: 0 0 .375rem rgba(0,0,0,.16);
	overflow: hidden;
}

.dropdown-item{
	padding-top: .375rem;
	padding-bottom: .375rem;
	color: $font-dark;
	
	&:active{
		background-color: $color-main;
	}
}


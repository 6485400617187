//
// _text.scss
// 

.text-primary{
	color: $color-main !important;
}

.text-danger{
	color: $color-red !important;
}

.text-info{
	color: $color-red !important;
}

.text-muted{
	color: $font-gray !important;
}
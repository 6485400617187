//
// _home.scss
// 

.icon-text{
	display: block;
	text-align: center;
	img{
		display: inline-block;
		max-width: 60px;
	}
	span{
		display: block;
		margin-top: 0.5rem;
		font-size: px2rem(18);
		white-space: nowrap;
	}
}







//
// _alert.scss
// 

.alert-overlay{
	@include fixed(top 60px bottom 0 left 0 right 0);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1010;
	color: #fff;
	background-color: rgba(0,0,0, 0.66);
}




//
// _borders.scss
// 

.border,
.border-top,
.border-right,
.border-bottom,
.border-left{
	border-color: $border-light !important;
}
//
// _content.scss
// 

.wrapper{
	display: flex;
	flex-direction: column;
	// min-height: 100vh;
	// max-width: 768px;
	margin: 0 auto;
}

.main{
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-bottom: 3rem;
}

// section
.section{
	position: relative;
	padding: 1.5rem 0;

	&__title{
		margin-bottom: 1.5rem;
		padding-bottom: 0.375rem;
		border-bottom: 4px solid $color-gray;
	}

	// EDM 選擇版型容器 - 手機版
	@media screen and (max-width: 767px) {
		&.edm-list-wrap {
			> .container-fluid {
				> .row {
					width: 100vw;
					margin: 0;
					padding-right: 3rem;
					flex-wrap: nowrap;
					overflow-x: auto;
	
					> div {
						width: 55vw;
						flex-shrink: 0;

						> label {
							width: 100%;
							display: flex;
							flex-direction: column;

							// text
							.col-4 {
								order: 2;
								width: 100%;
								max-width: 100%;
								margin-top: 1.2rem;
								margin-bottom: 0.875rem;

								.custom-control {
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}

							// img
							.col-8 {
								order: 1;
								width: 100%;
								max-width: 100%;

								.img-fluid {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

.section-title{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 1.5rem;
	padding-bottom: 0.375rem;
	border-bottom: 4px solid $color-gray;
}

.section-empty{
	display: flex;
	flex: 1;
	text-align: center;

	.container{
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		align-items: center;
	}
}

.section-cover{
	padding: 0;
	background-color: #383635;
	text-align: center;
}

.block{
	margin-bottom: 1.5rem;

	&__header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	&__title{
		margin-bottom: 0;
		font-size: px2rem(15);
		line-height: 1;
	}
}

















//
// _basic.scss
// 

body{
	font-family: $font-family;
	// background-color: #f9f9f9;
	line-height: 1.6;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-overflow-scrolling: touch;
	/* ios5+ */
}

a{
	color: $font-main;
	&:hover{
		color: darken($font-main, 10%);
		text-decoration: none;
	}
}

.small, small {
  font-size: px2rem(14);
}

button{
	@include reset-button();
	&:focus{
		outline: 0;
	}
	&:not(:disabled):not(.disabled) {
	  cursor: pointer;
	}
}

hr{
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: $color-gray-20;
	border-width: 2px;
}

[v-cloak] {
  display: none;
}

// form
// input[type=number]::-webkit-inner-spin-button, 
// input[type=number]::-webkit-outer-spin-button { 
//   -webkit-appearance: none; 
//   margin: 0; 
// }

// ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//   color: $font-light !important;
// }
// ::-moz-placeholder { /* Firefox 19+ */
//   color: $font-light !important;
// }
// :-ms-input-placeholder { /* IE 10+ */
//   color: $font-light !important;
// }
// :-moz-placeholder { /* Firefox 18- */
//   color: $font-light !important;
// }





//
// _type.scss
// 

// headings
.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6{
	// margin-bottom: 0.75rem;
	// font-weight: 400;
	line-height: 1.3;
}

h1, .h1{
	@include heading-size($h1-font-size);
}
h2, .h2{
	@include heading-size($h2-font-size);
}
h3, .h3{
	@include heading-size($h3-font-size);
}
h4, .h4{
	@include heading-size($h4-font-size);
}
h5, .h5{
	@include heading-size($h5-font-size);
}
// h6, .h6{
// 	@include heading-size($h6-font-size);
// }

.font-weight-medium{
	font-weight: 500;
}

.lead{
	font-weight: 400 !important;
}
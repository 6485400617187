
.width {
  width: 100%;
}
.edm1240 .edm-style-1 .body .right .sales-box canvas {
  width: 100px !important;
  height: 100px !important;
  right: 40px !important;
  top: 40px !important;
}
.EDM-loader {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(56, 55, 53, 0.7);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remark {
  margin-top: 20px;
  font-size: 0.875rem;
  color: #383735;
}
@media (max-width:900px) {
  .imgContent {
    width: 100% !important;
  }
}
.imgContent {
  width: 620px;
}
.imgHTML {
  width: 100%;
}

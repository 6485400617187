// //
// // _custom-forms.scss
// // 

.custom-select{
	font-size: px2rem(14);
	border-color: $color-main;
	&:focus{
		border-color: $color-dark;
		box-shadow: none;
	}
}

// 隱藏下拉多選
.custom-select[multiple]{
  height: 35px;
  opacity: 0;
}

// checkbox, radio, switch
.custom-control-input{
	&:checked ~ .custom-control-label::before {
	  border-color: $color-main;
	  background-color: $color-main;
	}

	&:focus ~ .custom-control-label::before {
		border-color: $color-main;
	  box-shadow: none;
	}

	&:focus:not(:checked)~.custom-control-label::before{
		border-color: rgba($color-main, .5);
	}

	&:not(:disabled):active ~ .custom-control-label::before {
	  background-color: rgba($color-main, .1);
	  border-color: rgba($color-main, .1);
	}
}

.custom-control-label{
	cursor: pointer;

	&:before, &:after{
		@include size(px2rem(18));
		top: 2px;
	}
}

// checkbox, radio
.custom-checkbox, .custom-radio{
	.custom-control-input{
		&:checked ~ .custom-control-label::after{
			background-size: px2rem(10px);
		}
	}
	.custom-control-label{
		&:before{
			border: 1px solid $border-color;
		}
	}
}

// switch
.custom-switch{
	padding-left: px2rem(43);

	.custom-control-input{
		&:checked ~ .custom-control-label::before{
			border-color: $color-green;
			background-color: $color-green;
		}

		&:checked ~ .custom-control-label::after{
			transform: translateX(1.1rem);
		}

		&:focus:not(:checked)~.custom-control-label::before{
			border-color: rgba($color-green, .5);
		}
	}

	.custom-control-label{
		&:before{
			left: px2rem(-43);
			width: px2rem(37);
			border-radius: 50rem;
		}
		
		&:after{
			top: calc(.12rem + 3px);
			left: calc(-2.15rem - 5px);
			width: calc(1rem - 4px);
			height: calc(1rem - 4px);
		}
	}

	&.--no-text{
		margin-right: -6px;
	}
}






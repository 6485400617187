//
// _tables.scss
// 

// grid table
.table-resp{
	margin-bottom: 1rem;
	padding: 0;
	border-top: 1px solid $border-light;

	// 網格垂直置中
	[class*="col-"]{
		position: static;
		align-self: center;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	// list item
	li{
		position: relative;
		list-style: none;
		margin: 0;
		padding: .125rem 0;
		border-bottom: 1px solid $border-light;
		@include breakpoint(sm){
			padding: .25rem 0;
		}
	}

	// 欄位說明
	.header{
		display: none;
		@include breakpoint(sm){
			display: flex;
		}
		[class*="col-"]{
			white-space: nowrap;
		}
	}

	.detail{
		[class*="col-"]{
			@media (max-width: 575px) {
				display: flex;
				justify-content: space-between;
			}
		}
		span{
			text-align: right;
			@include breakpoint(md){
				text-align: left;
			}
		}
	}

	// 手機版文字標頭
	.label{
		color: $color-gray;
		white-space: nowrap;
		@include breakpoint(sm){
			display: none;
		}
	}
}

// 原生table
.table{
	td, th{
		border-top: 0;
		@include breakpoint(md){
			padding: 0.5rem 0;
		}
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
	}
	thead{
		th{
			padding-top: 0;
			padding-bottom: 0;
			border-bottom: 3px solid $border-color;
			white-space: nowrap;
		}
	}
	tbody{
		tr:first-child{
			td{
				padding-top: 1.35rem;
			}
		}
	}
}









//
// _navbar.scss
// 

.navbar{
	padding: 0.5rem;
	background-color: #383635;
	.eva{
		@include size(28px);
		fill: #fff;
	}
}

.navbar-brand{
	img{
		max-width: 130px;
	}
}

.navbar-toggler{
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border: 0;
	// 關閉
	&[aria-expanded="false"]{
		.eva-menu{
			display: block;
		}
		.eva-close{
			display: none;
		}
	}
	// 展開
	&[aria-expanded="true"]{
		.eva-menu{
			display: none;
		}
		.eva-close{
			display: block;
		}
	}
}

.navbar-nav{
	.nav-link{
		display: flex;
		align-items: center;
		padding: 0.5rem;
		color: #fff;
	}
}

.navbar-collapse{
	.nav-link{
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		justify-content: flex-end;
	}
}





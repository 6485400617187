//
// _buttons.scss
// 

.btn{
	font-weight: 500;
	&:focus{
		box-shadow: none !important;
		outline: 0;
	}
}

$buttons:
  "primary" $color-main #fff,
  "danger" $color-main #fff,
  "light" $color-gray-20 $font-dark;

@each $name, $color, $text in $buttons {
  .btn-#{$name}{
    color: $text !important;
  	border: 1px solid $color;
  	background-color: $color;
  	&:focus,
  	&:hover,
  	&:not(:disabled):not(.disabled):active,
  	&:not(:disabled):not(.disabled).active{
  		color: $text;
  		border-color: darken($color, 10%);
  		background-color: darken($color, 10%);
  	}
  	&.disabled,
  	&:disabled{
  		border: 1px solid $color;
  		background-color: $color;
  	}
  }
}

.btn-default{
  color: $font-gray;
  &:hover{
    color: darken($font-gray, 10%);
  }
}

// outline
.btn-outline-primary{
	color: $color-main;
	border-color: $color-main;
	&:hover{
		border-color: $color-main;
		background-color: $color-main;
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active{
		border-color: darken($color-main, 10%);
		background-color: darken($color-main, 10%);
	}
}




//
// _variables.scss
// 

// color
$color-main: #C0AC79;

$color-light: #EBEBEB;
$color-gray: #767676;
$color-gray-70: rgba(#707070, 0.7);
$color-gray-30: rgba(#707070, 0.3);
$color-gray-20: rgba(#707070, 0.2);
$color-gray-10: rgba(#707070, 0.1);
$color-dark: #302D2C;

$color-blue: #2b49d3;
$color-red: #FF2727;
$color-orange: #FF8900;
$color-yellow: #F9AF4C;
$color-green: #28a745;
$color-cyan: #00AFC9;

$color-load: $color-gray-10;
$color-disabled: $color-gray-10;

// social color
$color-fb: #1877f2;
$color-line: #16c464;

// font
$font-size: 16px; //mixin px2rem
$font-main: $color-main;
$font-dark: #212529;
$font-gray: $color-gray;
$font-light: $color-light;
$font-family: "Roboto", "PingFang TC", 微軟正黑體, "Microsoft JhengHei", sans-serif;

// border
$border-light: #dddddd;
$border-color: #BBBBBB;
$border-dark: $color-dark;

// radius
$border-radius: .25rem;

// shadow
$box-shadow: 0 2px 6px rgba(0,0,0,.16);

// type
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
//
// _pagination.scss
// 

.pagination{

	.page-item{
		&.active{
			.page-link{
				cursor: default;
				color: #fff !important;
			}
		}
	}

	.page-link{
		cursor: pointer;
		&:focus{
			box-shadow: none;
		}
	}
}

[aria-label="Pagination Navigation"]{
	margin-top: 1.5rem;
	font-size: px2rem(14);
}




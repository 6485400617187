// 
// _sweetalert.scss
// 

.swal-modal{
  // border-radius: 0;

  .swal-button{
    // margin-bottom: 12px;
    // padding-top: 6px;
    // padding-bottom: 7px;
    // border-radius: 0;
    font-weight: 400;
    box-shadow: none !important;
    transition: background .15s;
    &--confirm{
      background-color: $color-main !important;
      &:hover{
        background-color: darken($color-main, 10%) !important;
      }
    }
    // &--cancel{
    //   background-color: $color-light !important;
    //   &:hover{
    //     background-color: darken($color-light, 10%) !important;
    //   }
    // }
  }
}
//
// _style.scss
// 

// fonts
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap";
// light 300
// regular 400
// medium 500
// bold 700

// utils
@import "utils/breakpoint";
@import "utils/variables";
@import "utils/mixin";

// base
@import "base/basic";
@import "base/borders";
@import "base/grid";
@import "base/icons";
@import "base/text";
@import "base/type";

// components
@import "components/alert";
// @import "components/badge";
// @import "components/breadcrumb";
// @import "components/borders";
@import "components/buttons";
@import "components/card";
@import "components/custom-forms";
@import "components/dropdown";
@import "components/forms";
// @import "components/modal";
@import "components/nav";
@import "components/pagination";
@import "components/tables";

// layout
@import "layout/content";
// @import "layout/footer";
@import "layout/navbar";

// pages
@import "pages/home";
@import "pages/edm";
@import "pages/estate";
// @import "pages/auth";

// vendors
// @import "vendors/magnific-popup/magnific-popup";
@import "vendors/multiple-select/multiple-select";
// @import "vendors/owlcarousel/owl.carousel";
// @import "vendors/owlcarousel/owl.theme.default";

// @import "vendors/dropzone";
@import "vendors/holdon";
@import "vendors/multiple-select";
// @import "vendors/owlcarousel";
@import "vendors/sweetalert";


// Edm's Pages
@import "pages/style-1";
@import "pages/style-2";
@import "pages/style-3";

// 列印頁面
@import "pages/print";
















// 
// _grid.scss
// 

.container{
	// max-width: 768px;
}

// blank頁面
body.container{
	@media (max-width: 499px) {
		padding-left: 0;
		padding-right: 0;
	}
}
//
// _forms.scss
// 

label{
	margin-bottom: 0.25rem;
	font-size: px2rem(14);
}

.form-row{
  margin-right: -10px;
  margin-left: -10px;
  &>.col, &>[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.form-group{
	position: relative;
	@include breakpoint(md){
		margin-bottom: 1.5rem;
	}		
	label{
		span{
			margin-left: 0.25rem;
			color: $color-red;
		}
	}
}

.form-control{
	height: calc(1.5em + 0.75rem + 5px);
	font-size: px2rem(14);
	border: 1px solid $color-main;
	&:focus{
		border: 1px solid $border-dark;
		box-shadow: none;
	}
}

// 表單驗證
input.error{
	border: 1px solid $color-red;
	&:focus{
		border: 1px solid $color-red;
	}
}
label.error,
.error.help-block{
	display: block;
	width: 100%;
	margin-top: 0.25rem;
	margin-bottom: 0;
	font-size: px2rem(14);
	font-style: normal;
	color: $color-red;
}













//
// _holdon.scss
// 

.sk-bounce{
	margin-left: -5px;
}

#holdon-message{
	h4{
		font-size: 1.25rem;
		@include breakpoint(lg){
			font-size: 1.375rem;
		}
	}
}
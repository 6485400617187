// 
// _edm.scss
// 

.fixed-bar {
    @include fixed(bottom 0 left 0 right 0);
    font-size: px2rem(14);
    background-color: #fff;
    box-shadow: $box-shadow;

    [class*="col"] {
        display: flex;
        align-items: center;
    }

    .count {
        font-size: px2rem(20);
        color: $font-main;
    }

    .btn {
        display: block;
        width: 100%;
        padding: 0.75rem;
        border-radius: 0;
        font-size: px2rem(18);
    }
}

.y-container {
    width: 620px;
    // height: 400px;

    @media (max-width:900px) {
        width: 100%;
        height: 100%;
    }

    // .edm-style-1 {
    //     font-weight: 500;
    //     background-color: #E3E3E3;
    //     // 新增屬性
    //     width: 500px;

    //     b {
    //         font-weight: 500;
    //     }

    //     .text-danger {
    //         color: #F24E4E !important;
    //     }

    //     .image {
    //         position: relative;
    //         padding-bottom: 59.5%;

    //         .cover {
    //             @include size();
    //             position: absolute;
    //             object-fit: cover;
    //         }

    //         .logo {
    //             @include absolute(top 13.6% left 0);
    //             width: 38.3%;
    //         }
    //     }

    //     .header {
    //         position: relative;
    //         width: 80.8%;
    //         margin: -6% auto 2%;

    //         .slogan {
    //             @include absolute(top 0 left 0);
    //             padding: 0.9% 3%;
    //             color: #fff;
    //             font-size: 0.5rem;
    //             background-color: #C4B78A;
    //             transform: translate(13%, -50%);
    //         }

    //         .title {
    //             padding: 5%;
    //             color: #C4B78A;
    //             font-size: 1rem;
    //             font-weight: bold;
    //             background-color: rgba(#252527, 0.9);
    //             text-align: center;
    //         }
    //     }

    //     .body {
    //         position: relative;
    //         display: flex;
    //         flex-wrap: nowrap;
    //         width: 80.8%;
    //         margin: 0 auto 3%;
    //         color: #5A5A5A;

    //         .box {
    //             padding: 5%;
    //         }

    //         .list {
    //             list-style: none;
    //             margin: 0;
    //             padding: 0;

    //             li {
    //                 margin-bottom: 5%;
    //                 font-size: 0.25rem;

    //                 &:last-child {
    //                     // margin-bottom: 0;
    //                 }
    //             }

    //             .label {
    //                 display: flex;
    //                 align-items: center;
    //                 margin-bottom: 1%;
    //                 color: #C7BA8D;
    //                 font-size: 0.75rem;
    //             }
    //         }

    //         .left {
    //             width: 53.5%;
    //             padding-top: 3.5%;
    //             padding-bottom: 3.5%;
    //             margin-right: 2.5%;
    //             background: #fff url('~@/assets/imgs/edm/bg.png') bottom right no-repeat;
    //             background-size: contain;

    //             .title {
    //                 display: block;
    //                 margin-bottom: 10%;
    //                 padding-bottom: 4%;
    //                 color: #5A5A5A;
    //                 font-size: 0.55rem;
    //                 border-bottom: 0.125rem solid #5A5A5A;
    //                 text-align: center;
    //             }
    //         }

    //         .right {
    //             width: 44%;
    //             background-color: #fff;

    //             .box {
    //                 padding-left: 6%;
    //                 padding-right: 6%;
    //             }

    //             .space {
    //                 height: 2.8%;
    //                 background-color: #E3E3E3;
    //             }

    //             .total-box {
    //                 background-color: #C4B78A;
    //                 color: #252527;
    //                 text-align: center;

    //                 .total {
    //                     font-size: 0.5rem;

    //                     b {
    //                         font-size: 1.5rem;
    //                     }
    //                 }

    //                 .text {
    //                     font-size: 0.75rem;
    //                     white-space: nowrap;
    //                 }
    //             }

    //             .sales-box {
    //                 display: flex;
    //                 align-items: center;
    //                 padding-top: 15%;
    //                 padding-bottom: 15%;
    //                 color: #252527;

    //                 .avatar {
    //                     position: relative;
    //                     width: 7rem;
    //                     padding-bottom: 120%;
    //                     margin-right: 25px;
    //                     img {
    //                         @include size();
    //                         position: absolute;
    //                         object-fit: cover;
    //                     }
    //                 }

    //                 .title {
    //                     margin-left: 0.25rem;

    //                     .job-title {
    //                         font-size: 0.15rem;
    //                         color: #C7BB93;
    //                     }

    //                     .name {
    //                         font-size: 0.35rem;
    //                     }
    //                 }
    //             }

    //             .store-title {
    //                 padding: 5%;
    //                 font-size: 0.75rem;
    //                 text-align: center;
    //                 background-color: #E9E4D3;
    //             }

    //             .store-box {
    //                 padding-top: 8%;
    //                 padding-bottom: 8%;

    //                 .list {
    //                     li {
    //                         font-size: 0.75rem;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     .footer {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         padding: 3%;
    //         background-color: #E9E4D3;

    //         .title {
    //             margin-right: 3%;
    //             font-size: 0.5rem;
    //             font-weight: bold;
    //         }

    //         .count {
    //             margin-left: 1.5%;
    //             padding: 0.5% 2.5%;
    //             border: 1px solid #252527;
    //             border-radius: 50rem;
    //             text-align: center;
    //             line-height: 1.4;

    //             .number {
    //                 display: block;
    //                 font-size: 0.5rem;
    //                 font-weight: bold;
    //             }

    //             .text {
    //                 display: block;
    //                 font-size: 0.25rem;
    //             }
    //         }
    //     }
    // }
}



// 通用
.headSection {
    background-color: #E3E3E3;
    padding-top: 48px;
    padding-right: 50px;


    .left-box {
        min-width: 475px;
        margin-right: 21px;

        .logoImage {
            img {
                width: 100%;
            }
        }

        .storeName {
            margin-top: 25px;

            .box-content {
                background-color: #E9E4D3;
                color: #5A5A5A;
                font-size: 24px;
                line-height: 35px;
                font-weight: 900;
                padding: 11px 23px;

                span {
                    &:nth-child(1) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .right-box {
        width: 100%;
        .sellerInfo {
            background-color: rgba($color: #ffffff, $alpha: 0.8);
            padding: 39px 0px 23px 35px;

            .leftSection {
                .sellerImage {
                    max-width: 120.5px;
                }
            }

            .middleSection {
                margin-left: 25px;
                height: auto;

                .sellerTitle {
                    span {
                        color: #C7BB93;
                        font-size: 26px;
                    }
                }

                .name {
                    span {
                        color: #000000;
                        font-size: 24px;
                    }
                }

                .phone {
                    span {
                        color: #000000;
                        font-size: 26px;
                    }
                }
            }

            .rightSection {
                margin-left: 47px;

                .componyName {
                    margin-bottom: 10px;

                    p {
                        font-size: 20px;
                        color: #5A5A5A;
                        line-height: 20px;

                        &:nth-child(1) {
                            margin-bottom: 6px;
                            font-size: 26px;
                            color: #C7BA8D;
                        }

                        &:nth-child(2) {
                            padding: 5px 0;
                        }
                    }
                }

                .componyAddress {

                    p {
                        font-size: 20px;
                        color: #5A5A5A;
                        line-height: 20px;

                        &:nth-child(1) {
                            margin-bottom: 6px;
                            font-size: 26px;
                            color: #C7BA8D;
                        }

                        &:nth-child(2) {
                            padding: 5px 0;
                        }
                    }
                }

            }
        }
    }


    p {
        margin-bottom: 0px;
    }
}

.headSection620 {
    background-color: #E3E3E3;
    padding-top: 48px;
    padding-right: 30px;


    .left-box {
        min-width: 240px;
        margin-right: 10px;

        .logoImage {
            img {
                width: 100%;
            }
        }

        .storeName {
            margin-top: 12.5px;

            .box-content {
                background-color: #E9E4D3;
                color: #5A5A5A;
                font-size: 14px;
                line-height: 17px;
                font-weight: 900;
                padding: 8px 11px;

                span {
                    &:nth-child(1) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .right-box {
        .sellerInfo {
            width: 100%;
            background-color: rgba($color: #ffffff, $alpha: 0.8);
            padding: 10px 0px 10px 17.5px;

            canvas {
                width: 65px !important;
                height: 65px !important;
                margin: 0 10px;
            }

            .leftSection {
                width: 20%;
                .sellerImage {
                    max-width: 100px;
                }
            }

            .middleSection {
                width: 35%;
                margin-left: 12.5px;
                height: auto;

                .sellerTitle {
                    span {
                        color: #C7BB93;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }

                .name {
                    span {
                        color: #000000;
                        font-size: 15px;
                    }
                }

                .phone {
                    span {
                        color: #000000;
                        font-size: 20px;
                    }
                }
            }

            .rightSection {
                width: 45%;
                margin-left: 47px;
                
                .componyName {
                    margin-bottom: 5px;

                    p {
                        font-size: 15px;
                        color: #5A5A5A;
                        line-height: 14px;

                        &:nth-child(1) {
                            margin-bottom: 6px;
                            font-size: 20px;
                            color: #C7BA8D;
                            font-weight: bold;
                        }

                        &:nth-child(2) {
                            padding: 5px 0;
                        }
                    }
                }

                .componyAddress {

                    p {
                        font-size: 15px;
                        color: #5A5A5A;
                        line-height: 14px;

                        &:nth-child(1) {
                            margin-bottom: 6px;
                            font-size: 20px;
                            color: #C7BA8D;
                            font-weight: bold;
                        }

                        &:nth-child(2) {
                            padding: 5px 0;
                        }
                    }
                }

            }
        }
    }


    p {
        margin-bottom: 0px;
    }
}
//
// _multiple-select.scss
// 

.ms-choice{
	> span{
		top: 3px;
		padding-left: 0.75rem;
	}

	> span.placeholder {
	  // color: #6d757d;
	  color: #495057;
	}
}


.ms-drop{
	color: #495057;

	&.bottom{
		width: calc(100% + 2px);
		margin-top: 0;
		margin-left: calc(-0.75rem - 1px);
		border: 1px solid $color-main;
		border-radius: .25rem;
		box-shadow: none;
	}

	ul{
		padding: 0.5rem 0.75rem;
		> li label{
			display: flex;
			align-items: center;
			margin: 0.25rem 0;
			span{
				margin-left: 0.125rem;
				color: #495057;
			}
		}
	}

	input[type=checkbox]{
		@include size(px2rem(16));
		position: relative;
		margin-right: 0.375rem;
		opacity: 0;
		&:checked{
			~ span{
				position: relative;
				&:before{
					border-color: #C0AC79;
			    background-color: #C0AC79;
				}
				&:after{
					background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23fff%27 d=%27M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z%27/%3e%3c/svg%3e');
					background-size: 0.625rem;
				}
			}
		}

		~ span{
			position: relative;
			line-height: 1.6;
			&:before, &:after{
				position: absolute;
				top: 2px;
				left: -1.5rem;
				display: block;
				width: 1.125rem;
				height: 1.125rem;
				content: "";
			}
			&:before{
				pointer-events: none;
				border: 1px solid #BBBBBB;
				border-radius: .25rem;
		    background-color: #fff;
			}
			&:after{
				background: 50%/50% 50% no-repeat;
			}
		}

	}

}








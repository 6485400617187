//
// _nav.scss
// 

.nav-pills{
	.nav-link{
		color: $font-dark;
		font-size: px2rem(14);
		background-color: $color-light;
		transition: color .15s, background-color .15s;
		&:hover{
			color: darken($font-dark, 10%);
		}
		&.active{
			background-color: $color-main;
			&:hover{
				color: #fff;
			}
		}
	}
}








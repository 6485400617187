//style-3

//----------------
// Color
//----------------

$color-primary: #AC9B73;
$color-secondary: #C8BB8D;
$color-dark: #3E3A39;
$color-middle: #898989;
$color-light: #EFEFEF;

$color-white: #FFF;
$color-red: #D7000F;

$zoom: 1.05;


@page {
    size: A4 portrait;
}

// .page-wrap {
//     position: relative;
//     // 疊圖參考
//     &::before {
//         content: '';
//         width: 21cm;
//         height: 29.7cm;
//         display: block;
//         position: absolute;
//         top: 0;
//         left: 0;
//         opacity: 0.4;
//         background-repeat: no-repeat;
//         background-size: contain;

//         // background-image: url('@/assets/imgs/edm/ref/edm-01.png'); // 1
//         background-image: url('@/assets/imgs/edm/ref/edm-04.png'); // 2
//         // background-image: url('@/assets/imgs/edm/ref/edm-07.png'); // 4
//         // background-image: url('@/assets/imgs/edm/ref/edm-08.png'); // 6
//         // background-image: url('@/assets/imgs/edm/ref/edm-09.png'); // 8
//     }
// }

// edm連結版面放大至寬度1240
.edm1240{
    .page-wrap{
        transform: scale(1.488);
        transform-origin: top;
    }
}

.edm-3-1, .edm-3-2, .edm-3-4, .edm-3-6, .edm-3-8{
    .page-wrap {
        width:  21cm * $zoom;
        height: 29.7cm * $zoom;
        position: relative;
        margin: 0 auto;

        // 右側裝飾
        &::before,
        &::after {
            --box-w: calc(2.6979cm * 1.05);
            --box-h: calc(13.5385cm * 1.05);

            content: '';
            position: absolute;
            right: 0;
            display: block;
            box-sizing: border-box;
        }
        &::before {
            top: 0;
            z-index: 1200;
            border-bottom: calc( var(--box-h) / 2 ) solid transparent;
            border-left: calc( var(--box-w) / 2 ) solid transparent;
            border-top: calc( var(--box-h) / 2 ) solid $color-secondary;
            border-right: calc( var(--box-w) / 2 ) solid $color-secondary;
        }
        &::after {
            bottom: 2.6231cm * $zoom;
            z-index: 1000;
            border-top: calc( var(--box-h) / 2 ) solid transparent;
            border-left: calc( var(--box-w) / 2 ) solid transparent;
            border-bottom: calc( var(--box-h) / 2 ) solid $color-secondary;
            border-right: calc( var(--box-w) / 2 ) solid $color-secondary;
        }
    }

    .page-header {
        height: 1.65cm * $zoom;
        box-sizing: border-box;
        position: relative;
        z-index: 900;

        .logo-outer-wrap {
            background-color: $color-dark;
            position: absolute;
            top: 0;
            left: 0;
            width: 6.8888cm * $zoom;
            height: 2.2264cm * $zoom;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-wrap {
                width: 5cm * $zoom;
                height: 1.1198cm * $zoom;
                img{
                    max-width: 100%;
                }
            }
        }

        .branch-name {
            width: 9.2214cm * $zoom;
            margin-left: 6.8888cm * $zoom;
            padding: 0.68cm * $zoom 0.4cm * $zoom;

            span {
                color: $color-primary;
                font-size: 16pt;
                font-weight: bold;
            }
        }
    }

    .page-footer {
        height: 2.6231cm * $zoom;
        padding: 0.5cm * $zoom 0.73cm * $zoom 0.56cm * $zoom 0.94cm * $zoom;
        box-sizing: border-box;
        background-image: url('@/assets/imgs/edm/footer-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        column-gap: 2.45cm * $zoom;
        position: relative;
        z-index: 2000;

        .footer-logo {
            flex-shrink: 0;
            width: 7.745cm * $zoom;
            height: 1.4971cm * $zoom;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .footer-inner {
            display: flex;
            flex-direction: column;

            .footer-title-logo {
                flex-shrink: 0;
                width: 3.334cm * $zoom;
                height: 0.6cm;
                // padding-bottom: 6pt;
                border-bottom: 1pt solid $color-secondary;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        
            .footer-des {
                margin: 0;
                margin-top: 3pt;
                color: $color-white;
                font-size: 9pt;
            }
        }
    }

    .contact-wrap {
        background-color: $color-light;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.55cm * $zoom;
        padding: 0.35cm * $zoom 1.98cm * $zoom 0.39cm * $zoom 0.94cm * $zoom;
        box-sizing: border-box;

        .info-wrap {
            display: flex;
            justify-content: center;
            column-gap: 0.5cm * $zoom;

            .img-wrap {
                width: 2.4cm * $zoom;
                height: 2.4cm * $zoom;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text-wrap {

                // 標題
                .info-title {
                    color: $color-primary;
                    font-size: 11pt;
                    line-height: 1;
                    letter-spacing: 1pt;
                    font-weight: bold;
                    display: block;

                    & + .info-title {
                        margin-top: 7pt;
                    }
                }

                // 姓名
                .info-name {
                    margin: 0;
                    color: $color-dark;
                    font-size: 23.775pt;
                    line-height: 1.2;
                    letter-spacing: 0.25pt;
                    font-weight: bold;
                    margin-top: 5pt;
                }

                // 電話
                .info-tel {
                    margin: 0;
                    color: $color-dark;
                    font-size: 23.775pt;
                    line-height: 1;
                    letter-spacing: 1.4pt;
                    font-weight: bold;
                    white-space: nowrap;
                }

                // 地址
                .info-address {
                    margin: 0;
                    margin-top: 2pt;
                    color: $color-dark;
                    font-weight: bold;
                    font-size: 12pt;
                    letter-spacing: 0.3pt;
                    line-height: 1.4;
                }
            }
        }
    }

    .page-body {
        position: relative;
        z-index: 1100;
        margin-top: 0.57cm * $zoom;
        height: calc( 100% - 2.2264cm * $zoom - 2.6231cm * $zoom );
        display: flex;
        flex-direction: column;

        .contact-wrap {
            flex-shrink: 0;
        }

        .page-body-inner {
            flex-grow: 1;
            padding: 0.74cm * $zoom 2.15cm * $zoom 0.7cm * $zoom 0;
        }


        // 物件
        .card-wrap {
            cursor: pointer;

            // 物件圖片
            .card-hero-img,
            .card-img {
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            // 物件標題
            .card-title {
                margin-bottom: 0;
                background-color: rgba($color-dark, 0.8);
                box-sizing: border-box;

                p {
                    margin: 0;
                    color: $color-white;
                    font-weight: bold;
                }
            }

            // 物件資訊
            .card-detail {

                // 清單標題
                .detail-list-title {
                    color: $color-dark;
                    font-weight: bold;
                    border-bottom: 2pt solid $color-secondary;
                }

                // 資訊清單
                .detail-list {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    // 細節項目
                    .list-item {

                        // 項目標題
                        .item-title {
                            color: $color-primary;
                            font-weight: bold;
                        }
        
                        // 項目內容
                        .item-content {
                            font-weight: bold;
                            color: $color-dark;
                        }
                    }
                }
            }

            // 物件價格
            .card-price {
                background-color: $color-red;
                display: flex;
                align-items: center;
                justify-content: center;
                
                span {
                    line-height: 1;
                    color: $color-white;
                    text-align: center;
                    font-weight: bold;
                }
            }
        }
    }
}

//--------------------------
// 根據物件數量決定排版樣式
//--------------------------


// 1個物件
.edm-3-1{
    .layout-count-1 {

        .page-header {
            z-index: 1200;

            .branch-name {
                padding: 0.5cm * $zoom 0.4cm * $zoom;
            }
        }

        .page-body {
            margin-top: 0;
            height: calc( 100% - 1.6564cm * $zoom - 2.6231cm * $zoom );

            .contact-wrap {
                padding: 0.35cm * $zoom 1.5cm * $zoom 0.39cm * $zoom 0.94cm * $zoom;
            }

            // 物件
            .card-wrap {
                height: 100%;
                display: flex;
                flex-direction: column;

                .card-hero-wrap {
                    position: relative;
                    padding-bottom: 0.88cm * $zoom;
                
                    // 首圖
                    .card-hero-img {
                        height: 7.7122cm * $zoom;
                    }

                    // 物件標題
                    .card-title {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 18.6894cm * $zoom;
                        height: 1.756cm * $zoom;
                        padding: 0 26pt;
                        background-color: transparent;
                        background-image: linear-gradient(to right, rgba($color-primary, 1), transparent);

                        display: flex;
                        align-items: center;

                        p {
                            font-size: 24.969pt;
                            // line-height: 1.756cm * $zoom;
                            letter-spacing: 0.3pt;
                            max-width: 14cm;
                            line-height: 1;
                        }
                    }

                    // 物件價格
                    .card-price {
                        position: absolute;
                        bottom: 0.36cm * $zoom;
                        right: 2.03cm * $zoom;
                        width: 4.0532cm * $zoom;
                        height: 1.1094cm * $zoom;

                        span {
                            font-size: 24.969pt;
                            letter-spacing: 1pt;
                        }
                    }
                }

                // 物件內容
                .card-info {
                    display: flex;
                    column-gap: 27.8pt;
                    padding: 0.9cm * $zoom 2cm * $zoom 0 1cm * $zoom;

                    .card-info-inner {
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.36cm * $zoom;

                        // 物件圖片
                        .card-img {
                            flex-shrink: 0;
                            width: 5.0197cm * $zoom;
                            height: 3.7648cm * $zoom;
                        }
                    }

                    // 物件資訊
                    .card-detail {
                        flex-grow: 1;

                        // 清單標題
                        .detail-list-title {
                            font-size: 14pt;
                            line-height: 1.7;
                            margin-bottom: 15pt;
                        }

                        // 資訊清單
                        .detail-list {
                            display: flex;
                            flex-direction: column;
                            row-gap: 0.2cm * $zoom;

                            // 細節項目
                            .list-item {
                                display: flex;
                                flex-direction: column;

                                // 項目標題
                                .item-title {
                                    display: block;
                                    font-size: 12pt;
                                    line-height: 1.6;

                                    // left icon
                                    &::before {
                                        content: '';
                                        display: inline-block;
                                        margin-right: 0.36cm * $zoom;

                                        border-left: 0.2496cm * $zoom solid $color-secondary;
                                        border-top: 0.2cm * $zoom solid transparent;
                                        border-bottom: 0.2cm * $zoom solid transparent;
                                        box-sizing: border-box;
                                    }
                                }
                
                                // 項目內容
                                .item-content {
                                    display: block;
                                    margin-left: 0.6cm * $zoom;
                                    font-size: 14pt;
                                    line-height: 1.5;
                                    letter-spacing: 0.25pt;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 2個物件
.edm-3-2{
    .layout-count-2 {

        .page-body {

            .page-body-inner {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 0.8cm * $zoom;
            }
        
            // 物件
            .card-wrap {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
        
                // 物件標題
                .card-title {
                    flex-shrink: 0;
                    min-height: 1.2318cm * $zoom;
                    padding: 2pt 6pt;
                    display: flex;
                    align-items: center;
                    justify-content: center;
        
                    p {
                        font-size: 17.515pt;
                        text-align: center;
                        letter-spacing: 0.6pt;
                        line-height: 1;
                    }
                }
        
        
                .card-header {
                    flex-shrink: 0;
                    position: relative;
                    padding: 0;
        
                    // 物件圖片
                    .card-img {
                        height: 6.3633cm * $zoom;
                    }
        
                    // 物件價格
                    .card-price {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 3.0677cm * $zoom;
                        height: 0.8cm * $zoom;
                        
                        span {
                            font-size: 18.898pt;
                            letter-spacing: 1pt;
                        }
                    }
                }
        
        
                // 物件資訊
                .card-detail {
                    flex-grow: 1;
                    padding: 0.78cm * $zoom 0 0 0.72cm * $zoom;
        
                    // 清單標題
                    .detail-list-title {
                        font-size: 14pt;
                        line-height: 1.3;
                        margin-bottom: 16pt;
                    }
        
                    // 資訊清單
                    .detail-list {
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.2cm * $zoom;
        
                        // 細節項目
                        .list-item {
                            display: flex;
                            flex-direction: column;
        
                            // 項目標題
                            .item-title {
                                display: block;
                                font-size: 12pt;
                                line-height: 1.6;
        
                                // left icon
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    margin-right: 0.36cm * $zoom;
        
                                    border-left: 0.2496cm * $zoom solid $color-secondary;
                                    border-top: 0.2cm * $zoom solid transparent;
                                    border-bottom: 0.2cm * $zoom solid transparent;
                                    box-sizing: border-box;
                                }
                            }
            
                            // 項目內容
                            .item-content {
                                display: block;
                                margin-left: 0.58cm * $zoom;
                                font-size: 14pt;
                                line-height: 1.5;
                                letter-spacing: 0.25pt;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 4個物件
.edm-3-4{
    .layout-count-4 {

        .page-body {
            
            .page-body-inner {
                display: flex;
                flex-direction: column;
                row-gap: 0.7cm * $zoom;
                padding: 0.6cm * $zoom 3.15cm * $zoom 0.7cm * $zoom 0;
            }
        
            // 物件
            .card-wrap {
                flex-grow: 1;
                display: flex;
        
                .card-info {
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
        
                    // 物件標題
                    .card-title {
                        flex-shrink: 0;
                        width: 6.9cm;
                        // height: 1.0266cm * $zoom;
                        padding: 0.16cm * $zoom 0.1cm * $zoom;
                        
                        p {
                            font-size: 14pt;
                            text-align: center;
                            // line-height: 1.0266cm * $zoom;
                            line-height: 1.1;
                            letter-spacing: 0.3pt;
                        }
                    }
        
                    .card-header {
                        flex-grow: 1;
                        position: relative;
                        padding: 0;
            
                        // 物件圖片
                        .card-img {
                            width: 6.5651cm * $zoom;
                            height: 100%;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center center;
                        }
            
                        // 物件價格
                        .card-price {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 2.3536cm * $zoom;
                            height: 0.6cm * $zoom;
                            
                            span {
                                font-size: 14.498pt;
                            }
                        }
                    }
                }
        
        
                // 物件資訊
                .card-detail {
                    flex-grow: 1;
                    padding: 0.74cm * $zoom 0 0.4cm * $zoom 0.6cm * $zoom;
                    border-bottom: 2pt solid $color-secondary;
        
                    // 資訊清單
                    .detail-list {
                        display: grid;
                        grid-template-columns: 0.64fr 0.5fr;
                        row-gap: 12pt;
                        column-gap: 12pt;
        
                        // 細節項目
                        .list-item {
                            display: flex;
                            flex-direction: column;
        
                            // 項目標題
                            .item-title {
                                display: block;
                                font-size: 11.817pt;
                                line-height: 1.7;
        
                                // left icon
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    margin-right: 0.36cm * $zoom;
        
                                    border-left: 0.2496cm * $zoom solid $color-secondary;
                                    border-top: 0.2cm * $zoom solid transparent;
                                    border-bottom: 0.2cm * $zoom solid transparent;
                                    box-sizing: border-box;
                                }
                            }
            
                            // 項目內容
                            .item-content {
                                display: block;
                                margin-left: 0.58cm * $zoom;
                                font-size: 13.786pt;
                                line-height: 1.5;
                                letter-spacing: 0.25pt;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 6個物件
.edm-3-6{
    .layout-count-6 {

        .page-body {
            
            .page-body-inner {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 0.52cm * $zoom;
                row-gap: 0.42cm * $zoom;
                padding: 0.6cm * $zoom 2.18cm * $zoom 0 0;
            }
        
            // 物件
            .card-wrap {
                display: flex;
                flex-direction: column;
        
                // 物件標題
                .card-title {
                    flex-shrink: 0;
                    height: 1.0266cm * $zoom;
                    box-sizing: border-box;
                    padding: 0.25cm * $zoom;
                    display: flex;
                    align-items: center;
                    justify-content: center;
        
                    p {
                        font-size: 13pt;
                        text-align: center;
                        letter-spacing: 0.6pt;
                        line-height: 1.1;
                    }
                }
        
                .card-header {
                    flex-shrink: 0;
                    position: relative;
                    padding: 0;

                    // 物件圖片
                    .card-img {
                        height: 3.4418cm * $zoom;
                    }
        
                    // 物件價格
                    .card-price {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 2.3536cm * $zoom;
                        height: 0.6cm * $zoom;
                        
                        span {
                            font-size: 14.498pt;
                            letter-spacing: 1pt;
                        }
                    }
                }
        
        
                // 物件資訊
                .card-detail {
                    flex-grow: 1;
                    padding: 0.28cm * $zoom 0.2cm * $zoom 0 0.38cm * $zoom;
        
                    // 資訊清單
                    .detail-list {
                        display: flex;
                        flex-direction: column;
                        row-gap: 3pt;
        
                        // 細節項目
                        .list-item {
                            display: flex;
                            flex-direction: column;
        
                            // 項目標題
                            .item-title {
                                display: block;
                                font-size: 10.256pt;
                                line-height: 1.8;
        
                                // left icon
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    margin-right: 0.25cm * $zoom;

                                    border-left: 0.2496cm * $zoom solid $color-secondary;
                                    border-top: 0.2cm * $zoom solid transparent;
                                    border-bottom: 0.2cm * $zoom solid transparent;
                                    box-sizing: border-box;
                                }
                            }
            
                            // 項目內容
                            .item-content {
                                display: block;
                                margin-left: 0.5cm * $zoom;
                                font-size: 11.965pt;
                                line-height: 1.25;
                                letter-spacing: 0.25pt;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 8個物件
.edm-3-8{
    .layout-count-8 {

        .page-body {

            .page-body-inner {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 0.1cm * $zoom;
                row-gap: 1cm * $zoom;
                padding: 0.68cm * $zoom 2.15cm * $zoom 0.85cm * $zoom 0;
            }
        
            // 物件
            .card-wrap {
                display: flex;
        
                .card-info {
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    width: 5.91cm;

                    // 物件標題
                    .card-title {
                        flex-shrink: 0;
                        min-height: 0.8158cm * $zoom;
                        padding: 0.1cm * $zoom;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            font-size: 13pt;
                            text-align: center;
                            // line-height: 0.8158cm * $zoom;
                            letter-spacing: 0.3pt;
                            line-height: 1.1;
                        }
                    }
        
                    .card-header {
                        flex-grow: 1;
                        position: relative;
                        padding: 0;

                        // 物件圖片
                        .card-img {
                            // position: absolute;
                            width: 5.6278cm * $zoom;
                            height: 100%;
                        }
            
                        // 物件價格
                        .card-price {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 2.3536cm * $zoom;
                            height: 0.6cm * $zoom;
                            
                            span {
                                font-size: 14.498pt;
                            }
                        }
                    }
                }
        
        
                // 物件資訊
                .card-detail {
                    flex-grow: 1;
                    padding: 0 0 0 0.28cm * $zoom;
        
                    // 資訊清單
                    .detail-list {
                        display: flex;
                        flex-direction: column;
                        row-gap: 3.2pt;
        
                        // 細節項目
                        .list-item {
                            display: flex;
                            flex-direction: column;
        
                            // 項目標題
                            .item-title {
                                display: block;
                                font-size: 9.3517pt;
                                line-height: 1.4;
                            }
            
                            // 項目內容
                            .item-content {
                                display: block;
                                font-size: 10.91pt;
                                line-height: 1.25;
                                letter-spacing: 0.25pt;
                            }
                        }
                    }
                }
            }
        }
    }
}
// 通用css設定
.w-1240 {
    width: 1240px;
}

.w-620 {
    width: 220mm;
}

.px-120 {
    padding: 0px 119.45px;
}

.px-50 {
    padding: 0px 50px;
}

.px-30 {
    padding: 0px 30px;
}

.default-bg {
    background-color: #E3E3E3;
}

.y-content {
    overflow: hidden;
}

.address{
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.address-wrap{
    height: 1em;
    line-height: 1em;
    white-space: normal;
}

.objectCard {
    b {
        font-weight: 500;
    }

    .text-danger {
        color: #F24E4E !important;
    }

    .slogan {
        color: #fff;
        font-size: 0.5rem;
    }

    .title {
        color: #C4B78A;
        font-weight: bold;
        background-color: rgba(#252527, 0.9);
        text-align: center;
    }


    .list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            font-weight: 800;
            color: #5A5A5A;
        }

        .label {
            display: flex;
            align-items: center;
            color: #C7BA8D;
        }

    }

    .baseInfo {
        background: #fff url('~@/assets/imgs/edm/bg.png') bottom right no-repeat;
        background-size: contain;

        .title {
            display: block;
            color: #5A5A5A;
            border-bottom: 4px solid #5A5A5A;
            text-align: center;
            background-color: #fff;
        }

        .list {
            li {
                .label {
                    margin-top: 6px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .text {
                    white-space:nowrap;
                }

                .text-danger {
                    font-size: 20px;
                }
            }
        }
    }

    .total-box {
        background-color: #C4B78A;
        color: #252527;
        text-align: center;



    }
}


//style-1-1
.edm-1-1 {
    width: 1240px;
    margin: 0 auto;

    .edm-style-1 {
        font-weight: 500;
        background-color: #E3E3E3;

        b {
            font-weight: 500;
        }

        .text-danger {
            color: #F24E4E !important;
        }

        .image {
            position: relative;
            padding-bottom: 59.5%;

            .cover {
                @include size();
                position: absolute;
                object-fit: cover;
            }

            .logo {
                @include absolute(top 13.6% left 0);
                width: 38.3%;
            }
        }

        .header {
            position: relative;
            width: 80.8%;
            margin: -6% auto 2%;

            .slogan {
                @include absolute(top 0 left 0);
                padding: 0.9% 3%;
                color: #fff;
                font-size: 0.5rem;
                background-color: #C4B78A;
                transform: translate(13%, -50%);
            }

            .title {
                padding: 5%;
                color: #C4B78A;
                font-size: 1rem;
                font-weight: bold;
                background-color: rgba(#252527, 0.9);
                text-align: center;
            }
        }

        .body {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            width: 80.8%;
            margin: 0 auto 3%;
            color: #5A5A5A;

            .box {
                padding: 5%;
            }

            .list {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 5%;
                    font-size: 14px !important;

                    &:last-child {
                        // margin-bottom: 0;
                    }
                }

                .label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1%;
                    color: #C7BA8D;
                    font-size: 0.75rem;
                    font-weight: bold;
                    // &:before{
                    // 	@include size(1.5vw);
                    // 	display: inline-block;
                    // 	margin-top: -1%;
                    // 	margin-right: 1%;
                    // 	content: "";
                    // 	background: url(../images/edm/icon.png) center center no-repeat;
                    // 	background-size: contain;
                    // }
                }
            }

            .left {
                width: 53.5%;
                padding-top: 3.5%;
                padding-bottom: 3.5%;
                margin-right: 2.5%;
                background: #fff url('~@/assets/imgs/edm/bg.png') bottom right no-repeat;
                background-size: contain;

                .title {
                    display: block;
                    margin-bottom: 10%;
                    padding-bottom: 4%;
                    color: #5A5A5A;
                    font-size: 0.55rem;
                    border-bottom: 0.125rem solid #5A5A5A;
                    text-align: center;
                }
            }

            .right {
                width: 44%;
                background-color: #fff;

                .box {
                    padding-left: 6%;
                    padding-right: 6%;
                }

                .space {
                    height: 2.8%;
                    background-color: #E3E3E3;
                }

                .total-box {
                    background-color: #C4B78A;
                    color: #252527;
                    text-align: center;

                    .total {
                        font-size: 0.5rem;

                        b {
                            font-size: 1.5rem;
                        }
                    }

                    .text {
                        font-size: 0.75rem;
                        white-space: nowrap;
                    }
                }

                .sales-box {
                    display: flex;
                    align-items: center;
                    padding-top: 15%;
                    padding-bottom: 15%;
                    color: #252527;
                    position: relative;

                    canvas {
                        margin-left: 30px;
                    }

                    .avatar {
                        position: relative;
                        width: 7rem;
                        padding-bottom: 120%;
                        margin-right: 25;

                        img {
                            @include size();
                            position: absolute;
                            object-fit: cover;
                        }
                    }

                    .title {
                        margin-left: 0.25rem;

                        .job-title {
                            font-size: 0.15rem;
                            color: #C7BB93;
                            font-weight: bold;
                        }

                        .name {
                            font-size: 20px !important;
                            line-height: 30px;
                        }
                    }
                }

                .qrcode {
                    display: flex;
                    justify-content: left;
                    align-items: left;
                    padding-left: 6%;
                    padding-right: 6%;
                }

                .store-title {
                    padding: 5%;
                    font-size: 0.75rem;
                    text-align: center;
                    background-color: #E9E4D3;
                }

                .store-box {
                    padding-top: 8%;
                    padding-bottom: 8%;

                    .list {
                        li {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }


    }
}

//style-1-2
.edm-1-2 {
    .edm-style-1-2 {
        .openEdm {
            padding: 60.5px 50px 0;

            .objectCard {
                .image {
                    min-width: 540px;
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                        height: auto;
                        max-height: 360px;
                        object-fit: cover;
                    }

                    .slogan {
                        position: absolute;
                        transform: translate(0, -100%);
                        font-size: 16px;
                        background-color: rgba($color: #C4B78A, $alpha: 0.89);

                    }
                }

                .title {
                    min-height: 122px;

                    span {
                        font-size: 30px;
                    }
                }

                .total-box {
                    .text {
                        font-size: 13px;
                        white-space: nowrap;
                        height: 38px;


                    }

                    .total {
                        font-size: 22px;

                        b {
                            font-size: 30px;
                        }
                    }
                }



                .baseInfo {
                    padding: 30px 30px 35px;
                    background-size: 70%;

                    .title {
                        min-height: unset;
                        font-size: 27px;
                    }


                    .list {
                        li {
                            font-size: 26px;

                            .label {
                                margin-top: 6px;
                                font-size: 28px;
                                font-weight: 600;
                            }

                            .text {
                                white-space:nowrap;
                            }

                            .text-danger {
                                font-size: 28px;
                            }
                        }
                    }
                }

            }
        }
    }
}

//style-1-4
.edm-1-4 {
    .edm-style-1-4 {
        .openEdm {
            padding: 60.5px 50px 0;

            .objectCard {

                .objectLeft {
                    max-width: 40%;
                    min-width: 40%;
                    height: 100%;
                    position: relative;
                    max-height: 350px;
                    min-height: 350px;

                    .image {
                        width: 100%;
                        height: 80%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            max-height: 280px;
                            min-height: 280px;
                        }

                    }

                    .title {
                        width: 100%;
                        height: 20%;
                        min-height: 120px;
                        position: relative;

                        span {
                            font-size: 30px;
                        }

                        .objectNo {
                            top: -25px;
                            left: 0px;
                            position: absolute;
                            height: 25px;
                            width: 25px;
                            font-size: 16px;
                            font-weight: 500;
                            background-color: #5A5A5A;
                        }
                    }
                }

                .objectRight {
                    max-width: 60%;
                    min-width: 60%;

                    .baseInfo {
                        padding: 30px 0px 0px 30px;
                        height: 80%;
                        background-size: 40%;
                        max-height: 280px;
                        min-height: 280px;

                        .title {
                            font-size: 27px;
                        }

                        .list {
                            li {
                                font-size: 26px;

                                .label {
                                    margin-top: 6px;
                                    font-size: 28px;
                                    font-weight: 600;
                                }

                                .text {
                                    white-space:nowrap;
                                }

                                .text-danger {
                                    font-size: 28px;
                                }
                            }
                        }
                    }

                    .total-box {
                        height: 20%;
                        min-height: 120px;

                        .text {
                            font-size: 13px;
                            white-space: nowrap;
                            height: 38px;


                        }

                        .total {
                            font-size: 30px;

                            b {
                                font-size: 40px;
                            }
                        }
                    }
                }

            }
        }
    }
}

//style-1-6
.edm-1-6 {
    .edm-style-1-6 {
        .openEdm {
            padding: 60.5px 50px 0;

            .objectCard {
                height: 100%;

                &:nth-child(2), &:nth-child(5) {
                    padding: 0 10px !important;
                }

                .image {
                    min-width: 100%;
                    position: relative;
                    height: 35%;
                    max-height: 250px;
                    min-height: 250px;

                    img {
                        max-height: 250px;
                        min-height: 250px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .slogan {
                        position: absolute;
                        transform: translate(0, -100%);
                        font-size: 14px !important;
                        background-color: rgba($color: #C4B78A, $alpha: 0.89);

                    }
                }

                .title {
                    min-height: 100px;
                    position: relative;

                    span {
                        font-size: 26px;
                    }

                    .objectNo {
                        top: -25px;
                        left: 0px;
                        position: absolute;
                        height: 25px;
                        width: 25px;
                        font-size: 16px;
                        font-weight: 500;
                        background-color: #5A5A5A;
                    }
                }

                .total-box {
                    .text {
                        font-size: 13px;
                        white-space: nowrap;
                        height: 38px;


                    }

                    .total {
                        font-size: 20px;

                        b {
                            font-size: 30px;
                        }
                    }


                }



                .baseInfo {
                    padding: 30px 25px 30px;
                    background-size: 50%;
                    max-height: 300px;
                    min-height: 300px;

                    .title {
                        min-height: unset;
                        font-size: 27px;
                    }

                    .list {
                        li {
                            font-size: 22px !important;

                            .label {
                                margin-top: 6px;
                                font-size: 26px;
                                font-weight: 600;
                            }

                            .text {
                                white-space:nowrap;
                            }

                            .text-danger {
                                font-size: 24px;
                            }
                        }
                    }
                }

            }
        }
    }
}

//style-1-8
.edm-1-8 {
    .edm-style-1-8 {
        .openEdm {
            padding: 60.5px 50px 54.5px;

            .objectCard {
                width: 50%;
                height: 100%;

                &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                    padding-left: 10px;
                }

                .objectLeft {
                    max-width: 50%;
                    min-width: 50%;
                    position: relative;
                    padding-right: 15px;

                    .image {
                        width: 100%;
                        max-height: 200px;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            min-height: 200px;
                            max-height: 200px;
                        }

                    }

                    .title {
                        width: 100%;
                        min-height: 65px;
                        height: auto;
                        position: relative;
                        padding-left: 0.25rem;
                        padding-right: 0.5rem;

                        span {
                            font-size: 20px;
                        }

                        .objectNo {
                            top: -25px;
                            left: 0px;
                            position: absolute;
                            height: 25px;
                            width: 25px;
                            font-size: 16px;
                            font-weight: 500;
                            background-color: #5A5A5A;
                        }
                    }

                    .total-box {
                        .text {
                            font-size: 13px;
                            white-space: nowrap;
                        }

                        .total {
                            font-size: 18px;

                            b {
                                font-size: 30px;
                            }
                        }
                    }

                }

                .objectRight {
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    transform: translateX(100%);
                    position: absolute;

                    .baseInfo {
                        width: 105%;
                        padding: 30px 20px 35px;
                        background-size: 45%;
                        height: 100%;
                        margin-left: -15px;

                        .title {
                            font-size: 27px;
                        }

                        .list {
                            li {
                                font-size: 22px;

                                .label {
                                    margin-top: 6px;
                                    font-size: 24px;
                                    font-weight: 600;
                                }

                                .text {
                                    white-space:nowrap;
                                }

                                .text-danger {
                                    font-size: 24px;
                                }
                            }
                        }

                    }
                }

            }
        }
    }
}



// 手機版面
//style-1-1
.edm-1-1.w-620 {
    width: 620px;
    margin: 0 auto;

    .edm-style-1 {
        font-weight: 500;
        background-color: #E3E3E3;

        b {
            font-weight: 500;
        }

        .text-danger {
            font-size: 16px;
            color: #F24E4E !important;
        }

        .image {
            position: relative;
            padding-bottom: 59.5%;

            .cover {
                @include size();
                position: absolute;
                object-fit: cover;
            }

            .logo {
                @include absolute(top 13.6% left 0);
                width: 38.3%;
            }
        }

        .header {
            position: relative;
            width: 80.8%;
            margin: -6% auto 2%;

            .slogan {
                @include absolute(top 0 left 0);
                padding: 0.9% 3%;
                color: #fff;
                font-size: 0.3rem;
                background-color: #C4B78A;
                transform: translate(13%, -50%);
                font-size: 17px !important;
            }

            .title {
                padding: 5%;
                color: #C4B78A;
                font-size: 1rem;
                font-weight: bold;
                background-color: rgba(#252527, 0.9);
                text-align: center;
            }
        }

        .body {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            width: 80.8%;
            margin: 0 auto 3%;
            color: #5A5A5A;

            .box {
                padding: 5%;
            }

            .list {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 5%;
                    font-size: 16px;

                    &:last-child {
                        // margin-bottom: 0;
                    }
                }

                .label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1%;
                    color: #C7BA8D;
                    font-size: 16px;
                    font-weight: bold;
                    // &:before{
                    // 	@include size(1.5vw);
                    // 	display: inline-block;
                    // 	margin-top: -1%;
                    // 	margin-right: 1%;
                    // 	content: "";
                    // 	background: url(../images/edm/icon.png) center center no-repeat;
                    // 	background-size: contain;
                    // }
                }
            }

            .left {
                width: 53.5%;
                padding-top: 3.5%;
                padding-bottom: 3.5%;
                margin-right: 2.5%;
                background: #fff url('~@/assets/imgs/edm/bg.png') bottom right no-repeat;
                background-size: contain;

                .title {
                    display: block;
                    margin-bottom: 10%;
                    padding-bottom: 4%;
                    color: #5A5A5A;
                    font-size: 15px;
                    border-bottom: 0.125rem solid #5A5A5A;
                    text-align: center;
                }
            }

            .right {
                width: 44%;
                background-color: #fff;

                .box {
                    padding-left: 6%;
                    padding-right: 6%;
                }

                .space {
                    height: 2.8%;
                    background-color: #E3E3E3;
                }

                .total-box {
                    background-color: #C4B78A;
                    color: #252527;
                    text-align: center;

                    .total {
                        font-size: 15px;

                        b {
                            font-size: 1.5rem;
                        }
                    }

                    .text {
                        font-size: 0.75rem;
                        white-space: nowrap;
                    }
                }

                .sales-box {
                    display: flex;
                    align-items: center;
                    padding-top: 10%;
                    color: #252527;

                    .avatar {
                        position: relative;
                        width: 4rem;
                        padding-bottom: 120%;

                        img {
                            @include size();
                            position: absolute;
                            object-fit: cover;
                        }
                    }

                    .title {
                        margin-left: 0.25rem;

                        .job-title {
                            font-size: 16px;
                            color: #C7BB93;
                            font-weight: bold;
                        }

                        .name {
                            font-size: 0.35rem;
                        }
                    }
                }

                .qrcode {
                    padding-bottom: 10%;
                }

                .store-title {
                    padding: 5%;
                    font-size: 0.75rem;
                    text-align: center;
                    background-color: #E9E4D3;
                }

                .store-box {
                    padding-top: 8%;
                    padding-bottom: 8%;

                    .list {
                        li {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }


    }
}

//style-1-2
.edm-1-2.w-620 {
    .edm-style-1-2 {
        .body {
            padding: 0 30px;
        }
        .mainContent {
            padding-top: 15px 0 !important;

            .objectCard_m {
                max-width: 49%;
                min-width: 49%;
                
                .image {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        max-height: 210px;
                        min-height: 210px;
                        object-fit: cover;
                    }

                    .slogan {
                        position: absolute;
                        transform: translate(0, -100%);
                        font-size: 8px !important;
                        background-color: rgba($color: #C4B78A, $alpha: 0.89);
                    }
                }

                .title {
                    min-height: 71px;

                    span {
                        font-size: 23px;
                    }
                }

                .total-box {
                    .text {
                        font-size: 13px;
                        white-space: nowrap;
                        height: 16px;
                    }

                    .total {
                        font-size: 20px;

                        b {
                            font-size: 30px;
                        }
                    }
                }



                .baseInfo {
                    padding: 10px 10px 12px;
                    background-size: 70%;

                    .title {
                        min-height: unset;
                        font-size: 20px;
                    }


                    .list {
                        li {
                            font-size: 20px;

                            .label {
                                margin-top: 6px;
                                font-size: 20px;
                                font-weight: 600;
                            }

                            .text {
                                white-space:nowrap;
                            }

                            .text-danger {
                                font-size: 20px;
                            }
                        }
                    }
                }

            }
        }
    }
}

//style-1-4
.edm-1-4.w-620 {
    .edm-style-1-4 {
        .mainContent {
            padding-top: 30.25px;

            .objectCard_m {

                .objectLeft {
                    max-width: 40%;
                    min-width: 40%;
                    height: 100%;
                    position: relative;
                    max-height: 150px;
                    min-height: 150px;

                    .image {
                        width: 100%;
                        max-height: 150px;
                        min-height: 150px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            max-height: 150px;
                            min-height: 150px;
                        }

                    }

                    .title {
                        width: 100%;
                        height: 20%;
                        min-height: 60px;
                        position: relative;

                        span {
                            font-size: 19px;
                        }

                        .objectNo {
                            top: -25px;
                            left: 0px;
                            position: absolute;
                            height: 25px;
                            width: 25px;
                            font-size: 16px;
                            font-weight: 500;
                            background-color: #5A5A5A;
                        }
                    }
                }

                .objectRight {
                    max-width: 60%;
                    min-width: 60%;

                    .baseInfo {
                        padding: 10px 0px 0px 10px;
                        height: 80%;
                        background-size: 40%;
                        max-height: 150px;
                        min-height: 150px;

                        .title {
                            font-size: 27px;
                        }

                        .list {
                            li {
                                font-size: 17px;
                                white-space: nowrap;

                                .label {
                                    margin-top: 6px;
                                    font-size: 18px;
                                    font-weight: 600;
                                }

                                .text {
                                    white-space:nowrap;
                                }

                                .text-danger {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .total-box {
                        height: 20%;
                        min-height: 60px;

                        .text {
                            font-size: 13px;
                            white-space: nowrap;
                            height: 38px;


                        }

                        .total {
                            font-size: 18px;

                            b {
                                font-size: 26px;
                            }
                        }
                    }
                }

            }
        }
    }
}

//style-1-6
.edm-1-6.w-620 {
    .edm-style-1-6 {
        .mainContent {
            padding-top: 30.25px;

            .objectCard {
                height: 100%;

                &:nth-child(2), &:nth-child(5) {
                    padding: 0 10px !important;
                }

                .image {
                    min-width: 100%;
                    position: relative;
                    height: 35%;
                    max-height: 125px;
                    min-height: 125px;

                    img {
                        max-height: 125px;
                        min-height: 125px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                }

                .title {
                    position: relative;

                    span {
                        font-size: 20px;
                    }

                    .objectNo {
                        top: -25px;
                        left: 0px;
                        position: absolute;
                        height: 25px;
                        width: 25px;
                        font-size: 16px;
                        font-weight: 500;
                        background-color: #5A5A5A;
                    }
                }

                .total-box {
                    .text {
                        font-size: 13px;
                        white-space: nowrap;
                        height: 19px;
                    }

                    .total {

                        font-size: 17px;

                        b {
                            font-size: 24px;
                        }
                    }


                }



                .baseInfo {
                    padding: 15px 8px 15px;
                    background-size: 50%;
                    min-height: 220px;

                    .title {
                        min-height: unset;
                        font-size: 13.5px;
                    }

                    .list {
                        li {
                            font-size: 18px !important;

                            .label {
                                margin-top: 6px;
                                font-size: 18px;
                                font-weight: 600;
                            }

                            .text {
                                white-space:nowrap;
                            }

                            .text-danger {
                                font-size: 18px;
                            }
                        }
                    }
                }

            }
        }
    }
}

//style-1-8
.edm-1-8.w-620 {
    .edm-style-1-8 {
        .body {
            padding: 0 30px;
        }

        .mainContent {
            padding: 30.25px 0px 27.25px;

            .objectCard_m {
                width: 50%;
                height: 100%;

                .objectLeft {
                    max-width: 50%;
                    min-width: 50%;
                    position: relative;
                    min-height: 200px;
                    max-height: 200px;

                    .image {
                        width: 100%;
                        max-height: 120px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            min-height: 104px;
                            max-height: 104px;
                        }

                    }

                    .title {
                        width: 100%;
                        height: auto;
                        position: relative;
                        padding-left: 0.25rem;
                        padding-right: 0.5rem;

                        min-height: 65px;
                        max-height: 65px;

                        span {
                            font-size: 17px;
                        }

                        .objectNo {
                            top: -25px;
                            left: 0px;
                            position: absolute;
                            height: 25px;
                            width: 25px;
                            font-size: 16px;
                            font-weight: 500;
                            background-color: #5A5A5A;
                        }
                    }

                    .total-box {
                        min-height: 31px;
                        max-height: 31px;

                        .text {
                            font-size: 6.5px;
                            white-space: nowrap;
                        }

                        .total {
                            font-size: 14px;

                            b {
                                font-size: 18px;
                            }
                        }
                    }

                }

                .objectRight {
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    transform: translateX(100%);
                    position: absolute;

                    .baseInfo {
                        width: 100%;
                        padding: 15px 0px 17.5px;
                        background-size: 45%;
                        height: 100%;
                        margin-left: -7px;

                        min-height: 200px;
                        max-height: 200px;


                        .title {
                            font-size: 13.5px;

                        }

                        .list {
                            li {
                                white-space: nowrap;
                                font-size: 15px;
                                padding-left: 5px;

                                .label {
                                    margin-top: 6px;
                                    font-size: 16px;
                                    font-weight: 600;
                                }

                                .text {
                                    white-space:nowrap;
                                }

                                .text-danger {
                                    font-size: 16px;
                                }
                            }
                        }

                    }
                }

            }
        }
    }
}

.objectCard_m {
    b {
        font-weight: 500;
    }

    .text-danger {
        color: #F24E4E !important;
    }

    .slogan {
        color: #fff;
        font-size: 0.5rem;
    }

    .title {
        color: #C4B78A;
        font-weight: bold;
        background-color: rgba(#252527, 0.9);
        text-align: center;
    }


    .list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            font-weight: 800;
            color: #5A5A5A;
        }

        .label {
            display: flex;
            align-items: center;
            color: #C7BA8D;
        }

    }

    .baseInfo {
        background: #fff url('~@/assets/imgs/edm/bg.png') bottom right no-repeat;
        background-size: contain;

        .title {
            display: block;
            color: #5A5A5A;
            border-bottom: 4px solid #5A5A5A;
            text-align: center;
            background-color: #fff;
        }

        .list {
            li {
                .label {
                    margin-top: 6px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .text {
                    white-space:nowrap;
                }

                .text-danger {
                    font-size: 20px;
                }
            }
        }
    }

    .total-box {
        background-color: #C4B78A;
        color: #252527;
        text-align: center;



    }
}
// 
// _card.scss
// 

.card{
	margin-bottom: 0.75rem;
	border: 1px solid $border-color;
	border-radius: $border-radius;

	&.is-select{
		border: 2px solid $color-main;
	}

	.card-body{
		padding: 1rem;
	}
}







